require(['jquery'], function ($) {

  /* floating header */
  var halfScreenSize = ($(document).width() * 0.5);
  $(window).scroll(function () {
    $this = $(this);
    var topSize = 31;
    if ($this.width() <= 960) {
      topSize = 42;
    }
    if ($this.scrollTop() >= topSize) {
      if (!$('#header').hasClass('floating')) {
        $('body').css('padding-top', $('.header-primary-container').height());
        $('#header').addClass('floating');
        $('#header').slideDown('fast');
      }
    }
    if ($this.scrollTop() < topSize) {
      if ($('#header').hasClass('floating')) {
        $('body').attr('style', '');
        $('#header').removeClass('floating');
        $('#header').attr('style', '');
      }
    }
  });

  $('.product_pricetag').each(function () { $(this).text('$') });

  if ($("#mini-cart").length > 0) {
    $("#mini-cart .icon.i-cart").removeClass("icon i-cart").addClass("fa fa-shopping-cart");
  }

  $(".form-search .button.button-search").html("<i class='fa fa-search'></i>");
  if ($(".logo-wrapper").length > 0) {
    $('.logo-wrapper').append("<div class='show-below-960 right nav-search'>" + $(".search-wrapper").html() + "</div>");
    if ($("#mini-cart").length > 0) {
      $('.logo-wrapper').prepend("<div class='show-below-960 right nav-cart'>" + $("#mini-cart").html() + "</div>");
    }
  }

  if ($(".variant-option").length > 0) {
    if ($(".variant-option .swatches-con .swatch img").length > 0) {
      $(".variant-option .swatches-con").css('display', 'block');
    }
  }

  $(window).on('resize', onResize);

  $(document).ready(function () {
    onResize();
  });

  var onResize = function () {
    if ($(window).width() < 960) {
      $(".nav-search .form-search .button.button-search").on("click", function (e) {
        if (!$(".nav-search").hasClass("fly-out")) {
          e.preventDefault();
          $(".nav-search").addClass("fly-out");
          $(".nav-search .form-search .input-text").focus();
          $(".nav-search .form-search .button.button-search").after("<button class='button button-close'><i class='fa fa-times'></i></button>");
        }
      });
    }
  };

  $(document).on('click', '.nav-search .button-close', function (e) {
    e.preventDefault();
    $(".nav-search").removeClass("fly-out");
  });

  $('.sidebar-category .block .block-title, .sidebar-filter .block .block-title').click(function () {
    $(this).toggleClass('open');
  });
});